import React, { FC, useMemo, memo } from "react"
import styled from "styled-components"
import dateFormat from "dateformat"
import NewsCard from "~/components/NewsCard"
import TagLabel from "~/components/TagLabel"
import { News } from "~/types"

const Wrapper = styled.div`
  width: 90%;
  ${({ theme }) => theme.media.lm`
    width: 100%;
  `}
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.title};
  text-align: center;
`

const NewsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 50px 0;
`

const Button = styled.button`
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  width: 60%;
  font-size: 1.5rem;
  background-color: white;
  &:hover {
    cursor: pointer;
  }
`

const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 60%;
  margin: 30px auto;
`

const SubTitle = styled.h2`
  margin-top: 100px;
  text-align: center;
  ${({ theme }) => theme.media.lm`
    text-align: center;
  `}
`

const TagWrapper = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

type Props = {
  data: News
  allData: News
  setNews: React.Dispatch<React.SetStateAction<News>>
  displayCount: number
  count: number
  setCount: React.Dispatch<React.SetStateAction<number>>
}

const Contents: FC<Props> = ({
  data,
  allData,
  setNews,
  displayCount,
  count,
  setCount,
}) => {
  const tagList = useMemo<string[]>(
    () =>
      allData
        .map(news => news.tag)
        .flat()
        .filter((news, index, self) => news && self.indexOf(news) === index),
    [allData]
  )

  const isDisplay = useMemo(() => data.length > count, [data, count])

  const searchTag = (tag: string) => {
    const pickedNews = allData.filter(
      news => news.tag && news.tag.includes(tag)
    )
    setNews(pickedNews)
    setCount(displayCount)
  }

  return (
    <Wrapper>
      <Title>News</Title>
      <NewsBox>
        {data.slice(0, count).map(news => (
          <NewsCard
            key={news.id}
            link={news.link}
            url={news.image.url}
            date={dateFormat(
              news.customDate ? news.customDate : news.createdAt,
              "isoDate"
            )}
            desc={news.title}
            tags={news.tag}
          />
        ))}
      </NewsBox>

      <ButtonWrapper style={{ display: isDisplay ? "" : "none" }}>
        <Button onClick={() => setCount(count + displayCount)}>
          もっと見る
        </Button>
      </ButtonWrapper>

      <SubTitle>ジャンルで探す</SubTitle>
      <TagBox>
        {tagList.map(tag => (
          <TagWrapper key={tag} onClick={() => searchTag(tag)}>
            <TagLabel tech={tag} />
          </TagWrapper>
        ))}
      </TagBox>
    </Wrapper>
  )
}

export default memo(Contents)
