import React, { FC, useMemo, memo } from "react"
import styled from "styled-components"
import dateFormat from "dateformat"
import { News } from "~/types"

const Wrapper = styled.div`
  margin: 30px 5px 30px 0;
  width: 150px;
  ${({ theme }) => theme.media.lm`
    margin: 30px auto;
    width: 95%;
  `}
`

const Title = styled.h2`
  margin: 20px 0;
  text-align: center;
`

const DateBox = styled.div`
  text-align: center;
  ${({ theme }) => theme.media.lm`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `}
`

const Date = styled.div`
  margin: 10px 0;
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  ${({ theme }) => theme.media.lm`
    margin-right: 20px;
  `}
`

type Props = {
  data: News
  setNews: React.Dispatch<React.SetStateAction<News>>
  displayCount: number
  setCount: React.Dispatch<React.SetStateAction<number>>
}

const Archive: FC<Props> = ({ data, setNews, displayCount, setCount }) => {
  const dateList = useMemo(() => {
    return data
      .map(news =>
        dateFormat(
          news.customDate ? news.customDate : news.createdAt,
          "yyyy-mm"
        )
      )
      .filter((date, index, self) => self.indexOf(date) === index)
  }, [data])

  const searchDate = (date: string) => {
    const pickedNews = data.filter(
      news =>
        dateFormat(
          news.customDate ? news.customDate : news.createdAt,
          "yyyy-mm"
        ) === date
    )
    setNews(pickedNews)
    setCount(displayCount)
  }

  return (
    <Wrapper>
      <Title>アーカイブ</Title>
      <DateBox>
        {dateList.map(date => (
          <Date key={date} onClick={() => searchDate(date)}>
            {date}
          </Date>
        ))}
      </DateBox>
    </Wrapper>
  )
}

export default memo(Archive)
