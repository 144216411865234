import React, { FC, useState } from "react"
import { PageProps, graphql } from "gatsby"
import styled from "styled-components"
import Head from "~/components/Head"
import Layout from "~/components/Layout"
import Archive from "~/components/NewsPage/Archive"
import Contents from "~/components/NewsPage/Contents"
import { News } from "~/types"

const DISPLAY_COUNT = 12

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.space.topTitlePage};
  ${({ theme }) => theme.media.lm`
    display: block;
  `}
`

const NewsPage: FC<PageProps<GatsbyTypes.AllNewsQuery>> = ({ data }) => {
  const allData = data.cms.someNews
  const [news, setNews] = useState<News>(allData)
  const [count, setCount] = useState<number>(DISPLAY_COUNT)

  return (
    <>
      <Head title="News" />
      <Layout>
        <Wrapper>
          <Contents
            data={news}
            allData={allData}
            setNews={setNews}
            displayCount={DISPLAY_COUNT}
            count={count}
            setCount={setCount}
          />
          <Archive
            data={allData}
            setNews={setNews}
            displayCount={DISPLAY_COUNT}
            setCount={setCount}
          />
        </Wrapper>
      </Layout>
    </>
  )
}

export const query = graphql`
  query AllNews {
    cms {
      someNews(orderBy: createdAt_DESC) {
        id
        title
        image {
          url
        }
        link
        tag
        createdAt
        customDate
      }
    }
  }
`

export default NewsPage
